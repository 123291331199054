import { AUTHORITY } from "constants/index";
import { getTime, format } from "date-fns";
import pako from "pako";

export const arraysEqual = (a, b) => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (var i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
};

export const sliceTableData = (data, currentPage, limit) => {
	if (!data) return [];
	return data.slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit);
};

export const setSelectOptions = (data, needNull = false) => {
	if (!data) return [];
	const result =
		data.length > 0
			? data.map((item) => {
					return { name: item.name, value: item.code };
			  })
			: [];

	if (needNull) {
		result.push({
			name: "---",
			value: "",
		});
	}
	return result;
};

export const blobDownload = (url, filename, type) => {
	const xhr = new XMLHttpRequest();
	xhr.open(type, url, true);
	xhr.responseType = "blob";
	const requestOnload = () => {
		xhr.onload = function (e) {
			if (this.status === 200) {
				const blob = this.response;
				const a = document.createElement("a");
				document.body.appendChild(a);
				const blobUrl = window.URL.createObjectURL(blob);
				a.href = blobUrl;
				a.download = filename;
				a.click();
				setTimeout(() => {
					window.URL.revokeObjectURL(blobUrl);
					document.body.removeChild(a);
				}, 0);
			}
		};
	};
	if (type === "POST") {
		xhr.setRequestHeader("Content-type", "application/json");
		requestOnload();
	}
	if (type === "GET") {
		requestOnload();
		xhr.send();
	}
};

const formatAuthority = (authorityValue) => {
	const authArray = authorityValue.toString(2).split("");
	let result = {};
	Object.entries(AUTHORITY).forEach(([key, value], index) => {
		result = { [key]: parseInt(authArray[index] || 0), ...result };
	});
	return result;
};

export const getTableAuthority = (type, authData) => {
	if (!authData || !type) {
		return {
			authority: 0,
			tableAuthority: {
				View: 0,
				Edit: 0,
				Lock: 0,
				Import: 0,
			},
		};
	}
	const authTableType = type.indexOf("_") !== -1 ? type.split("_")[0] + type.split("_")[1] : type;
	const { table, authority } = authData;
	const tableAuthority = table[authTableType.toUpperCase()] || 0;
	return {
		authority,
		tableAuthority: formatAuthority(tableAuthority),
	};
};

export const convertArrayToMap = (arr, key) =>
	arr.reduce((acc, v) => {
		if (v[key]) {
			acc[v[key]] = v;
		}
		return acc;
	}, {});

export const groupArr = (array, subGroupLength) => {
	var index = 0;
	var newArray = [];

	while (index < array.length) {
		newArray.push(array.slice(index, (index += subGroupLength)));
	}

	return newArray;
};

export const search = (target, compare) => compare.indexOf(target) !== -1;

export const calStudentYear = () => {
	const nowDate = new Date();
	const ROCYear = nowDate.getFullYear() - 1911;

	const studentYearDemarcationPoint = getTime(new Date(`${nowDate.getFullYear()}-08-01`));
	return getTime(nowDate) > studentYearDemarcationPoint ? ROCYear : ROCYear - 1;
};

export const filterEmptyObject = (data) => {
	if (typeof data === "object") {
		const nextData = { ...data };
		Object.entries(nextData).forEach(([key, value]) => {
			if (value === null || value === undefined || value === "") delete nextData[key];
		});
		return nextData;
	}
};

export const getSchoolYear = () => {
	const year = format(new Date(), "yyyy") - 1912;
	// 根據 PM 需求，五月之後自動帶入下一學年。
	if (new Date().getMonth() >= 4) {
		return year + 1;
	} else {
		return year;
	}
};

export const getStartDay = (date) => {
	if (!date) return;
	const startDay = new Date(new Date(date).toISOString().substring(0, 11) + "00:00:00");
	return startDay;
};

export const getEndDay = (date) => {
	if (!date) return;
	const startDay = new Date(new Date(date).toISOString().substring(0, 11) + "23:59:59");
	return startDay;
};

export const unGZip = (base64String) => {
	return pako.inflate(
		new Uint8Array(
			window
				.atob(base64String)
				.split("")
				.map((x) => x.charCodeAt(0)),
		),
		{ to: "string" },
	);
};
