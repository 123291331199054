import { Api } from "common-components";
import { stringify } from "query-string";

/**
 * 取得章節
 */
export const getChapters = async (payload) => {
	const queryString = stringify(payload);
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/questionstatus/querychapters/selection?${queryString}`,
	);
	return response;
};

/**
 * 取得需查詢的題目
 */
export const getReviewQuestions = async (params, body) => {
	const queryString = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/questionstatus/reviewquestions?${queryString}`,
		body,
	);
	return response;
};

/**
 * 下架題目
 */
export const deprecateQuestion = async (body) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/questionstatus/deprecationquestion`, body);
	return response;
};

/**
 * 上架題目
 */
export const publishQuestion = async (body) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/questionstatus/publicationquestion`, body);
	return response;
};

/**
 * 刪除 AI 解析
 */
export const deleteQuestionAIAnalyze = async (body) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/questionstatus/deletequestionaianalyze`, body);
	return response;
};

/**
 * 匯出題目
 */
export const exportQuestions = async (body) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/questionstatus/export`, body);
	return response;
};
