import styled from "styled-components";

export const UiQuestionStatusPage = styled.div`
	/* display: flex;
	flex-direction: column;
	height: 100%; */

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.searchToolBar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			> * {
				margin-right: 8px;
			}

			> div {
				margin-right: 8px;
				width: 130px;
			}

			.dateFilterBox {
				min-width: 250px;
			}
		}

		.actionBox {
			display: flex;

			> * {
				margin-left: 8px;
			}
		}
	}

	.ant-tabs-nav {
		margin-bottom: 0;
	}

	.ant-tabs-tab {
		color: #fff;
	}

	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.questionSearchTableContent {
		height: calc(100% - 44px);

		.questionSearchTable {
			height: calc(100% - 38px);
			margin-bottom: 8px;

			.tableNoData {
				margin: 24px auto;
				width: 50%;
				font-size: 24px;
				text-align: center;
				color: #fff;
			}
		}
	}

	.question {
		&__container {
			display: flex;
			margin-bottom: 8px;
			flex-direction: column;
		}
		&__item {
			display: flex;
			padding: 16px 0;
			flex-direction: column;
			row-gap: 8px;
			border-bottom: 1px solid #fff;

			* {
				color: #fff;
			}

			span {
				position: unset !important;
			}

			img {
				display: inline-block;
				width: unset;
				height: unset;
			}

			a {
				color: inherit;
				text-decoration: none;
				cursor: text;
			}
		}
		&__html {
			background-color: #fff;
			display: flex;
			flex-direction: column;
			align-self: flex-start;

			* {
				color: #000 !important;
			}
		}
		&__infos {
			display: flex;
			flex-direction: column;
			gap: 8px;

			&__item {
				display: flex;
			}

			&__label {
				min-width: 6em;
				text-align: right;
			}
			&__value {
			}
		}
		&__actions {
			display: flex;
			column-gap: 8px;
			justify-content: flex-end;
		}
	}
`;
