import {
	HomePage,
	EquationDojo,
	LivePreTest,
	LoginPage,
	RuleAddPage,
	RuleSearchPage,
	RuleSearchDetailPage,
	QuestionSearchPage,
	QuestionRevisePage,
	ExportListPage,
	ScheduleListPage,
	CheckListPage,
	AudiosImportPage,
	AudiosSearchPage,
} from "views";
import { MainLayout } from "layouts";
import { UpdateAvailabilityPage } from "views/UpdateAvailabilityPage/UpdateAvailabilityPage";
import { ErrorQuestionReportPage } from "views/ErrorQuestionReportPage/ErrorQuestionReportPage";
import MockExam from "views/MockExam";
import ValidationQuestions from "views/ValidationQuestions";
import VocabularyTest from "views/VocabularyTest";
import EduService from "views/EduService";
import EnglishListening from "views/EnglishListening";
import QuestionsStatisticsPage from "views/statistics/QuestionsStatisticsPage/QuestionSearchPage";
import QuestionStatusPage from "views/QuestionStatusPage";

const routes = [
	{
		path: "/login",
		component: LoginPage,
		exact: true,
	},
	{
		path: "/scheduleList",
		component: MainLayout,
		exact: true,
		routes: [
			{
				path: "/scheduleList",
				component: HomePage,
				name: "scheduleList",
				exact: true,
			},
		],
	},
	{
		path: "/ruleAdd",
		component: MainLayout,
		routes: [
			{
				path: "/ruleAdd",
				component: RuleAddPage,
				name: "ruleAdd",
				exact: true,
			},
		],
	},
	{
		path: "/ruleSearch",
		component: MainLayout,
		routes: [
			{
				path: "/ruleSearch",
				component: RuleSearchPage,
				name: "ruleSearch",
				exact: true,
			},
		],
	},
	{
		path: "/questionSearch",
		component: MainLayout,
		routes: [
			{
				path: "/questionSearch",
				component: QuestionSearchPage,
				name: "questionSearch",
				exact: true,
			},
		],
	},
	{
		path: "/ruleSearchDetail/:id",
		component: MainLayout,
		routes: [
			{
				path: "/ruleSearchDetail/:id",
				component: RuleSearchDetailPage,
				name: "ruleSearchDetail",
				exact: true,
			},
		],
	},
	{
		path: "/questionRevise",
		component: MainLayout,
		routes: [
			{
				path: "/questionRevise",
				component: QuestionRevisePage,
				name: "questionRevise",
				exact: true,
			},
		],
	},
	{
		path: "/exportList",
		component: MainLayout,
		routes: [
			{
				path: "/exportList",
				component: ExportListPage,
				name: "exportList",
				exact: true,
			},
		],
	},
	{
		path: "/questionsStatistics",
		component: MainLayout,
		routes: [
			{
				path: "/questionsStatistics",
				component: QuestionsStatisticsPage,
				name: "questionsStatistics",
				exact: true,
			},
		],
	},
	{
		path: "/equationDojo",
		component: MainLayout,
		routes: [
			{
				path: "/equationDojo",
				component: EquationDojo,
				name: "equationDojo",
				exact: true,
			},
		],
	},
	{
		path: "/livePreTest",
		component: MainLayout,
		routes: [
			{
				path: "/livePreTest",
				component: LivePreTest,
				name: "livePreTest",
				exact: true,
			},
		],
	},
	{
		path: "/mockExam",
		component: MainLayout,
		routes: [
			{
				path: "/mockExam",
				component: MockExam,
				name: "mockExam",
				exact: true,
			},
		],
	},
	{
		path: "/validationQuestions",
		component: MainLayout,
		routes: [
			{
				path: "/validationQuestions",
				component: ValidationQuestions,
				name: "validationQuestions",
				exact: true,
			},
		],
	},
	{
		path: "/vocabularyTest",
		component: MainLayout,
		routes: [
			{
				path: "/vocabularyTest",
				component: VocabularyTest,
				name: "vocabularyTest",
				exact: true,
			},
		],
	},
	{
		path: "/eduService",
		component: MainLayout,
		routes: [
			{
				path: "/eduService",
				component: EduService,
				name: "eduService",
				exact: true,
			},
		],
	},

	{
		path: "/englishListening",
		component: MainLayout,
		routes: [
			{
				path: "/englishListening",
				component: EnglishListening,
				name: "englishListening",
				exact: true,
			},
		],
	},
	{
		path: "/updateAvailability",
		component: MainLayout,
		routes: [
			{
				path: "/updateAvailability",
				component: UpdateAvailabilityPage,
				name: "updateAvailability",
				exact: true,
			},
		],
	},
	{
		path: "/errorQuestionReport",
		component: MainLayout,
		routes: [
			{
				path: "/errorQuestionReport",
				component: ErrorQuestionReportPage,
				name: "errorQuestionReport",
				exact: true,
			},
		],
	},
	{
		path: "/questionStatus",
		component: MainLayout,
		routes: [
			{
				path: "/questionStatus",
				component: QuestionStatusPage,
				name: "questionStatus",
				exact: true,
			},
		],
	},
	{
		path: "/checkList",
		component: MainLayout,
		routes: [
			{
				path: "/checkList",
				component: CheckListPage,
				name: "checkList",
				exact: true,
			},
		],
	},
	{
		path: "/audiosImport",
		component: MainLayout,
		routes: [
			{
				path: "/audiosImport",
				component: AudiosImportPage,
				name: "audiosImport",
				exact: true,
			},
		],
	},
	{
		path: "/audiosSearch",
		component: MainLayout,
		routes: [
			{
				path: "/audiosSearch",
				component: AudiosSearchPage,
				name: "audiosSearch",
				exact: true,
			},
		],
	},
	{
		path: "/",
		component: MainLayout,
		routes: [
			{
				path: "/",
				component: ScheduleListPage,
				name: "home",
				exact: true,
			},
		],
	},
];
export default routes;
